<template>
  <div>
    <el-form ref="form" :model="form" :rules="rules" >
      <el-form-item label="类型" prop="classify">
        <el-select v-model="form.classify"  placeholder="请选择类型" style="width: 100%">
          <el-option
              v-for="item in goodsClassify"
              :key="item.goodsCode"
              :label="item.goodsName"
              :value="item.goodsCode">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="运营商" prop="operator" >
        <el-select v-model="form.operator" placeholder="请选择运营商" style="width: 100%">
          <el-option
              v-for="item in operators"
              :key="item.optionValue"
              :label="item.optionName"
              :value="item.optionValue">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="地区" prop="area">
        <el-select v-model="form.area" placeholder="请选择地区" style="width: 100%">
          <el-option
              key="香港"
              label="香港"
              value="香港">
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
export default {
  name: 'SwitchIpForm',
  data() {
    return {
      loading: false,
      currentGoodsClassifyIndex: 0,
      goodsClassify: [],
      areas: [],
      operators: [],
      form: {
        classify: '',
        area: '',
        operator: '',
      },
      rules: {
        classify: [
          { required: true, message: '请选择类型', trigger: 'blur' },
        ],
        operator: [
          { required: true, message: '请选择运营商', trigger: 'blur' },
        ],
        area: [
          { required: true, message: '请选择地区', trigger: 'blur' },
        ]
      }
    }
  },
  created() {
    this.getGoodsClassify()
  },
  computed: {
    currentGoodsClassify: function () {
      if (this.goodsClassify.length > 0) {
        return this.goodsClassify[this.currentGoodsClassifyIndex]
      }
      return {}
    },
  },
  methods: {
    validate(callback){
      this.$refs['form'].validate((valid) => {
        callback(valid)
      });
    },
    getGoodsClassify() {
      this.$http.get('/feiyu/goods/getGoodsClassify')
          .then(res => {
            this.goodsClassify = res.data
            this.getGoodsSku()
          })
    },
    getGoodsSku() {
      this.loading = true
      this.$http.get('/feiyu/goods/getGoodsSku?goodsId=' + this.currentGoodsClassify.id).then(res => {
        this.areas = res.data.find(v => v.skuCode === 'AREA').options
        this.operators = res.data.find(v => v.skuCode === 'OPERATOR').options
      })
    },
    /*    getAvailableIp() {
          if(this.operatorSkuOptionCode && this.projectSkuOptionId && this.currentGoodsClassify){
            this.$http.get('/feiyu/goods/getAvailableIp?operator='
                + this.operatorSkuOptionCode
                + '&projectId=' + this.projectSkuOptionId
                + '&ipType=' + this.currentGoodsClassify.goodsCode
                + '&memberId=' +  (this.userInfo.id || '')
            ).then(res => {
              this.goodsSku.find(v => v.skuCode === 'AREA').options = res.data.map((item, index) => {
                return {
                  id: item.area,
                  optionName: item.area,
                  totalIpNum: item.totalIpNum,
                  selected: index === 0,
                  skuCode: 'AREA',
                  floatPrice: 0
                }
              })
            }).finally(() => {
              this.loading = false
            })
          }
        },*/
  }
}
</script>

<style scoped>

</style>
