<template>
    <div style="overflow-x: hidden">
        <el-row :gutter="0">
            <el-col :xs="10" :sm="6" :md="5" :lg="4" :xl="3">
                <el-card class="box-card-left">
                    <div class="info">
                        <el-avatar size="large" icon="el-icon-user-solid" src="circleUrl"></el-avatar>
                        <div style="margin-top: 10px">{{userInfo.phone}}</div>
                    </div>
                    <div style="height: 30px"></div>
                    <el-menu
                        :default-active="currentMenu"
                        @select="menuSelect"
                        class="el-menu-vertical-demo">
                        <el-menu-item index="accountInfo">
                            <i class="el-icon-user"></i>
                            <span slot="title">账号管理</span>
                        </el-menu-item>
                        <el-menu-item index="rechargeRecord" >
                            <i class="el-icon-s-order"></i>
                            <span slot="title">订单记录</span>
                        </el-menu-item>
                        <el-menu-item index="ipList">
                            <i class="el-icon-s-management"></i>
                            <span slot="title">IP管理</span>
                        </el-menu-item>
                        <el-menu-item index="realName" v-if="systemConfig.forceRealName">
                            <i class="el-icon-s-check"></i>
                            <span slot="title">实名认证</span>
                        </el-menu-item>
                        <el-menu-item index="proxyModule" v-if="userInfo.isProxy">
                            <i class="el-icon-s-flag"></i>
                            <span slot="title">代理管理</span>
                        </el-menu-item>
<!--                        <el-menu-item index="5">
                            <i class="el-icon-s-comment"></i>
                            <span slot="title">反馈中心</span>
                        </el-menu-item>-->
                    </el-menu>
                </el-card>
            </el-col>
            <el-col :xs="14" :sm="18" :md="19" :lg="20" :xl="21">
                <el-card class="box-card-main">
                    <AccountInfo v-if="currentMenu==='accountInfo'"></AccountInfo>
                    <RechargeRecord v-if="currentMenu==='rechargeRecord'"></RechargeRecord>
                    <IpList v-if="currentMenu==='ipList'" :package-id="packageId"></IpList>
                    <ProxyModule v-if="currentMenu==='proxyModule'"></ProxyModule>
                    <div v-if="currentMenu === 'realName'">
                        <h2>实名认证</h2>
                        <div v-if="userInfo.isReal">已认证</div>
                        <el-form v-else style="width: 70%;margin: auto">
                           <el-form-item label="姓名">
                               <el-input v-model="realNameForm.name" placeholder="请输入姓名"></el-input>
                           </el-form-item>
                            <el-form-item label="身份证号">
                                <el-input v-model="realNameForm.idCard" placeholder="请输入身份证号"></el-input>
                            </el-form-item>
                            <el-form-item>
                                <el-button type="primary" @click="doRealName">提交</el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                </el-card>
            </el-col>
        </el-row>
    </div>
</template>
<script>

import AccountInfo from "./AccountInfo.vue";
import RechargeRecord from "./RechargeRecord.vue";
import IpList from "@/components/IpList.vue";
import {tansParams} from "@/api/utils";
import ProxyModule from "@/components/ProxyModule.vue";
import {EventBus} from "@/api/eventBus";

export default {
    name: 'UserCenter',
    components: {ProxyModule, IpList, RechargeRecord, AccountInfo},
    data() {
        return {
            userInfo:{},
            packageId:undefined,
            currentMenu:'',
            realNameForm:{
                name:'',
                idCard:''
            }
        };
    },
    mounted() {
        const menuName = this.$route.query.menuName;
        if(menuName){
            this.currentMenu = menuName
        }
    },
    created() {
        this.getUserInfo()
        let currentMenu = sessionStorage.getItem("currentMenu")
        if(currentMenu){
            this.currentMenu = currentMenu
        }else{
            this.currentMenu = 'accountInfo'
        }
        EventBus.$on('changeMenuName',(menuName)=>{
            console.log('menuName:',menuName)
            this.currentMenu = menuName.name
            this.packageId = menuName.query
        })
    },
    computed: {
        systemConfig:function (){
            let systemConfigStr = localStorage.getItem('systemConfig');
            if (!systemConfigStr) {
                return {}
            }
            return JSON.parse(systemConfigStr)
        }
    },
    methods: {
        menuSelect(index){
            sessionStorage.setItem("currentMenu",index)
            this.currentMenu = index
        },
        getUserInfo() {
            this.$http.get('/feiyu/ip/info')
                .then(res => {
                    localStorage.setItem('userInfo', JSON.stringify(res.data))
                    this.userInfo = res.data
                }).catch(err => {
                this.$message.error(err.msg)
            })
        },
        doRealName(){
            if(this.realNameForm.name || this.realNameForm.idCard){
                this.$http.get('/feiyu/ip/authRealName?' + tansParams(this.realNameForm))
                    .then(res => {
                        this.getUserInfo()
                        this.$message.success('提交成功')
                    }).catch(err => {
                    this.$message.error(err.msg)
                })
            }else{
                this.$message.error('请输入姓名和身份证号')
            }
        }
    }
}
</script>

<style scoped>
.el-menu{
    border-right: none;
}
.box-card-left{
    margin: 10px;
    min-height: 700px;
}
.box-card-main{
    margin: 10px;
}
.info{
    text-align: center;
    font-size: 18px;
}
</style>
