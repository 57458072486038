<template>
    <div>
        <h2>账号信息</h2>
        <el-row :gutter="12">
            <el-col :span="12">
                <el-card shadow="hover">
                    <div class="card-item">
                        <div>用户名</div>
                        <div style="margin: 10px 0;font-size: 20px;font-weight: bold">{{userInfo.phone}}</div>
                        <el-button size="mini" type="primary" @click="changePasswordShow=true">修改密码</el-button>
                    </div>
                </el-card>
            </el-col>
            <el-col :span="12">
                <el-card shadow="hover">
                    <div  class="card-item">
                        <div>账户余额（鱼币）</div>
                        <div style="margin: 10px 0;font-size: 20px;font-weight: bold">{{userInfo.balance}}</div>
                        <el-button size="mini" type="primary" @click="handleRecharge">充值</el-button>
                    </div>
                </el-card>
            </el-col>
        </el-row>
        <div style="height: 10px"></div>


        <el-dialog
            title="修改密码"
            :visible.sync="changePasswordShow"
            width="30%">
            <div>
                <el-form :model="changePasswordForm" status-icon  ref="changePasswordForm" label-width="100px" class="demo-ruleForm">
                    <el-form-item label="手机号" prop="phone">
                        <el-input  v-model="changePasswordForm.phone" disabled autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="新密码" prop="password">
                        <el-input type="password" v-model="changePasswordForm.password" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="验证码">
                        <div class="container">
                            <el-input v-model="changePasswordForm.smsCode" clearable class="input"
                                      placeholder="请输入验证码"></el-input>
                            <el-button class="button" :disabled="counting" @click="getSmsCode"
                                       type="primary">{{
                                    counting ? `${count} 秒后重新获取` : '获取验证码'
                                }}
                            </el-button>
                        </div>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="changePasswordShow = false">取 消</el-button>
                <el-button type="primary" @click="doChangePassword">确 定</el-button>
          </span>
        </el-dialog>

    </div>
</template>
<script>

import {tansParams} from "@/api/utils";
import {EventBus} from "@/api/eventBus";

export default {
    name: 'AccountInfo',
    data() {
        return {
            changePasswordShow:false,
            changePasswordForm:{
                phone:'',
                password:'',
                smsCode:'',
                smsType:'PASSWORD',
            },
            count: 60,
            counting: false,
            timer: null,

        };
    },
    computed: {
        userInfo: function () {
            let userInfoStr = localStorage.getItem('userInfo');
            if (!userInfoStr) {
                return {}
            }
            let userInfoObj = JSON.parse(userInfoStr)
            this.changePasswordForm.phone = userInfoObj.phone
            return userInfoObj
        },
        proxyInfo: function () {
            let userInfoStr = localStorage.getItem('proxyInfo');
            if (!userInfoStr) {
                return {}
            }
            return JSON.parse(userInfoStr)
        }
    },
    components: {},
    created() {
        this.getUserInfo()
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    methods: {
        handleRecharge(){
            EventBus.$emit('openRecharge');
        },
        getSmsCode() {
            this.$http.post('/feiyu/login/sendSms?' + tansParams(this.changePasswordForm))
                .then(res => {
                    this.$message.success('发送成功')
                    this.startCountdown()
                }).catch(err => {
                this.$message.error(err.msg)
            })
        },
        //获取代理信息
        getProxyInfo() {
            this.$http.post('/feiyu/proxy/proxyInfo')
                .then(res => {
                    localStorage.setItem('proxyInfo', JSON.stringify(res.data))
                }).catch(err => {
                this.$message.error(err.msg)
            })
        },

        startCountdown() {
            if (this.counting) {
                return;
            }
            this.counting = true;
            this.timer = setInterval(() => {
                if (this.count > 0) {
                    this.count--;
                } else {
                    this.stopCountdown();
                }
            }, 1000);
        },
        doChangePassword(){
            this.$http.post('/feiyu/login/changePassword?' + tansParams(this.changePasswordForm))
                .then(res => {
                    this.$message.success('修改成功')
                    this.changePasswordShow=false
                    clearInterval(this.timer)
                    this.timer=null
                }).catch(err => {
                this.$message.error(err.msg)
            })
        },
        getUserInfo() {
            this.$http.get('/feiyu/ip/info')
                .then(res => {
                    localStorage.setItem('userInfo', JSON.stringify(res.data))
                    if(res.data.isProxy){
                        this.getProxyInfo()
                    }
                }).catch(err => {
                this.$message.error(err.msg)
            })
        },
    }
}
</script>

<style scoped>
.card-item{
    padding: 30px;
    text-align: center;
}
</style>
