import Vue from 'vue'
import Router from 'vue-router'
import Index from '../components/Index'
import UserCenter from "../components/UserCenter.vue";
import AccountInfo from "../components/AccountInfo.vue";
import Buy from "@/components/Buy.vue";

Vue.use(Router)
export default new Router({
    routes: [
        {
            path: '/',
            name: 'Index',
            component: Index
        },
        {
            path: '/center',
            name: 'UserCenter',
            component: UserCenter
        }, {
            path: '/accountInfo',
            name: 'AccountInfo',
            component: AccountInfo
        }, {
            path: '/buy',
            name: 'Buy',
            component: Buy
        }
    ]
})
