<template>
    <div class="kefu">
<!--
        <el-popover
            placement="left"
            width="200"
            trigger="hover">
            <div>
                <el-image :src="wx" style="width: 40%;"></el-image>
            </div>
            <div slot="reference" style="text-align: center">
                <el-image :src="qq" style="width: 40%;"></el-image>
                <div class="title">在线客服</div>
            </div>
        </el-popover>
-->

<!--
        <el-popover
            placement="left"
            width="200"
            trigger="hover"
            content="这是一段内容,这是一段内容,这是一段内容,这是一段内容。">
            <div slot="reference" style="text-align: center;margin: 30px 0">
                <el-image :src="wx" style="width: 40%;"></el-image>
                <div class="title">微信客服</div>
            </div>
        </el-popover>
-->

        <el-popover
            placement="left"
            width="200"
            trigger="hover"
            content="3999671536">
            <div>
                <h3 style="text-align: center">飞鱼科技</h3>
                <el-image :src="qqCode" style="width: 100%;"></el-image>
                <div style="text-align: center">3999671536</div>
            </div>
            <div slot="reference" style="text-align: center">
                <el-image :src="qqgroup" style="width: 40%;"></el-image>
                <div class="title">客服QQ</div>
            </div>
        </el-popover>

    </div>
</template>

<script>
import qq from '../assets/kefu/qq.png'
import qqgroup from '../assets/kefu/qq-group.png'
import qqCode from '../assets/kefu/qq_qrcode.png'
import wx from '../assets/kefu/wx.png'
export default {
    name: 'KeFu',
    data() {
        return {
            qq,qqgroup,wx,qqCode
        };
    },
    methods: {

    }
}
</script>


<style scoped>
.kefu{
    background-color: rgba(96, 103, 236, 0.8);
    padding: 20px;
    width: 80px;
    position: fixed;
    right: 10px;
    top: 320px;
    z-index: 999999;
}
.title{
    color: #fff;
    font-size: 14px;
}
</style>
