<template>
    <div style="text-align: center">
        <el-radio-group v-model="selected">
            <el-radio label="ALI_NATIVE" border>支付宝支付</el-radio>
            <el-radio label="WX_NATIVE" disabled border>微信支付</el-radio>
            <el-radio label="ACCOUNT_BALANCE" border :disabled="!canUseBalance">鱼币支付（¥{{ userInfo.balance }}）
            </el-radio>
        </el-radio-group>
        <div style="height: 20px"></div>
        <slot></slot>
        <div v-show="querying"><i class="el-icon-loading"></i>支付中...</div>
        <h3 v-if="amt">支付金额：{{ amt }}</h3>
        <canvas v-if="showQrCode" ref="qrcodeCanvas"></canvas>
        <h4 v-if="msg">{{msg}}</h4>
        <div style="margin-top: 80px;text-align: right">
            <el-button @click="cancel">取消</el-button>
            <el-button type="primary" :loading="loading" @click="pay">支付</el-button>
        </div>
    </div>
</template>


<script>
import QRCode from 'qrcode'

export default {
    name: 'TradeType',
    props: {
        canUseBalance: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            amt: 0,
            msg: null,
            showQrCode: false,
            querying: false,
            loading: false,
            intervalId: null,
            selected: 'ALI_NATIVE',
            tradeTypeList: [{
                name: '支付宝支付',
                code: 'ALI_NATIVE',
                icon: '/static/icon/alipay.png'
            }, {
                name: '微信支付',
                code: 'WX_NATIVE',
                icon: '/static/icon/wechat.png'
            }, {
                name: '鱼币支付',
                code: 'ACCOUNT_BALANCE',
                icon: '/static/icon/balance.png',
                desc: '可用余额：'
            }],
        }
    },
    beforeDestroy() {
        console.log('beforeDestroy tradeType')
        // 在组件销毁时清除定时器
        clearInterval(this.intervalId);
        this.intervalId = null
        this.amt = 0
        this.showQrCode = false
    },
    computed: {
        userInfo: function () {
            let userInfoStr = localStorage.getItem('userInfo');
            if (!userInfoStr) {
                return {}
            }
            return JSON.parse(userInfoStr)
        }
    },
    created() {
        console.log('created tradeType')
        this.getUserInfo()
    },
    methods: {
        generateQRCode(text) {
            if (text) {
                this.showQrCode = true
                this.$nextTick(() => {
                    const canvas = this.$refs.qrcodeCanvas
                    QRCode.toCanvas(canvas, text, error => {
                        if (error) console.error(error)
                    })
                })
            } else {
                if (this.selected === 'ACCOUNT_BALANCE') {

                }
            }
        },
        startInterval(orderId) {
            this.intervalId = setInterval(() => {
                this.queryOrderInfo(orderId)
            }, 3000)
        },
        queryOrderInfo(orderId) {
            this.$http.post('/feiyu/order/queryOrder?orderId=' + orderId).then(res => {
                console.log('res.data:', res.data)
                if (res.data.orderStatus === 'SUCCESS') {
                    console.log('支付成功')
                    this.querying = false
                    this.$emit('paySuccess', orderId)
                } else if (res.data.orderStatus === 'EXECUTE') {
                    console.log('执行中')
                    //将ref=qrcodeCanvas的二维码移除
                    this.showQrCode = false
                    this.msg = '支付成功；ip开通中请稍等...'
                } else if (res.data.orderStatus === 'PENDING') {
                    console.log('等待开通')
                } else if (res.data.orderStatus === 'FAIL') {
                    console.log('开通失败')
                }
            })
        },
        cancel() {
            this.$emit('close')
        },
        pay() {
            this.loading = true
            this.querying = true
            this.$emit('pay', this.selected)
        },
        getUserInfo() {
            this.$http.get('/feiyu/ip/info')
                .then(res => {
                    localStorage.setItem('userInfo', JSON.stringify(res.data))
                }).catch(err => {
                this.$message.error(err.msg)
            })
        },
    }
}
</script>

<style scoped>

</style>
