<template>
    <el-container style="background-color: #4038D9">
        <el-main style="margin: 0;padding: 0;overflow: hidden;">
            <div class="top-bg">
                <div class="top-title">
                    <div style="font-size: 48px;font-weight: 600">飞鱼云，新品初上，百万特惠</div>
                    <div style="font-size: 28px">专属技术顾问为您提供免费售前咨询、解决方案推荐等一对一服务</div>
                </div>
            </div>
            <div style="padding: 20px;color: #fff;text-align: center">
                <div class="top-2-title">使用项目</div>
                <div style="font-size: 22px">满足你业务的多样性和无限的可能性</div>
            </div>
            <div class="top-1" >
                <div class="top-1-item" @click="emitBuyIp">
                    <img src="../assets/icon/index/icon-index-1.png" alt="问卷调查">
                    <div>问卷调查</div>
                </div>
                <div class="top-1-item" @click="emitBuyIp">
                    <img src="../assets/icon/index/icon-index-2.png" alt="应用多开">
                    <div>应用多开</div>
                </div>
                <div class="top-1-item" @click="emitBuyIp">
                    <img src="../assets/icon/index/icon-index-3.png" alt="游戏炼金">
                    <div>游戏炼金</div>
                </div>
                <div class="top-1-item" @click="emitBuyIp">
                    <img src="../assets/icon/index/icon-index-4.png" alt="批量注册">
                    <div>批量注册</div>
                </div>
                <div class="top-1-item" @click="emitBuyIp">
                    <img src="../assets/icon/index/icon-index-5.png" alt="排名优化">
                    <div>排名优化</div>
                </div>
                <div class="top-1-item" @click="emitBuyIp">
                    <img src="../assets/icon/index/icon-index-6.png" alt="数据采集">
                    <div>数据采集</div>
                </div>
            </div>
            <div class="top-2">
                <div class="top-2-title">为什么选择飞鱼云</div>
                <div style="text-align: center;color: #fff;font-size: 22px">安全、诚信、可靠</div>
                <div style="display: flex;flex-direction: row;text-align: center;padding: 40px;margin-top: 60px">
                    <div style="flex: 1">
                        <el-image :src="icon1"></el-image>
                        <div style="color: #fff" slot="title">行业积累</div>
                    </div>
                    <div style="flex: 1">
                        <el-image :src="icon2"></el-image>
                        <div style="color: #fff" slot="title">服务保障</div>
                    </div>
                    <div style="flex: 1">
                        <el-image :src="icon3"></el-image>
                        <div style="color: #fff" slot="title">文档可靠</div>
                    </div>
                    <div style="flex: 1">
                        <el-image :src="icon4"></el-image>
                        <div style="color: #fff" slot="title">应急响应</div>
                    </div>
                    <div style="flex: 1">
                        <el-image :src="icon5"></el-image>
                        <div style="color: #fff" slot="title">合作共赢</div>
                    </div>
                </div>
            </div>
        </el-main>
        <el-footer style="background-color: #5B5EE9;height: auto">
            <Footer></Footer>
        </el-footer>

        <el-backtop :visibility-height="10"></el-backtop>
<!--        <KeFu></KeFu>-->
    </el-container>
</template>

<script>
import icon1 from '../assets/icon/icon-1.png'
import icon2 from '../assets/icon/icon-2.png'
import icon3 from '../assets/icon/icon-3.png'
import icon4 from '../assets/icon/icon-4.png'
import icon5 from '../assets/icon/icon-5.png'
import KeFu from "./KeFu.vue";
import Footer from "./Footer.vue";
import Buy from "@/components/Buy.vue";
import {EventBus} from "@/api/eventBus";

export default {
    name: 'Index',
    components: {Buy, KeFu, Footer},
    data() {
        return {
            icon1,
            icon2,
            icon3,
            icon4,
            icon5,
            activeIndex: '1',
        };
    },
    methods: {
        handleSelect(key, keyPath) {
            console.log(key, keyPath);
        },
        emitBuyIp(){
            EventBus.$emit('openBuyIp');
        }
    }
}
</script>


<style scoped>
.top-bg {
    height: 600px;
    background-image: url('../assets/bg-image-1.jpg');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 100%;
}

.top-title {
    color: #fff;
    padding: 220px;
}

.top-1 {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 定义三列，每列宽度相等 */
    gap: 20px; /* 设置项目之间的间距 */
    width: 70%;
    margin: 80px auto;
    padding: 30px;
}
.top-1-item{
    padding: 30px;
    background-color: #7786F9;
    text-align: center;
    color: #fff;
    cursor: pointer;
}
.top-2 {
    padding: 80px 200px;
    background-color: #7786F9;
}

.top-2-title {
    text-align: center;
    font-size: 34px;
    color: #fff;
    font-weight: bold;
    padding: 60px 0 20px 0;
}

</style>
