<template>
    <div>
        <el-form :inline="true" size="mini" :model="queryParams" class="demo-form-inline">
            <el-form-item label="IP">
                <el-input v-model="queryParams.ip" clearable></el-input>
            </el-form-item>
            <el-form-item label="套餐ID">
                <el-input v-model="queryParams.packageId" clearable></el-input>
            </el-form-item>
            <el-form-item label="地区">
                <el-input v-model="queryParams.area" clearable></el-input>
            </el-form-item>
            <el-form-item label="分组">
                <el-select v-model="queryParams.groupId" clearable @focus="getGroupList">
                    <el-option v-for="item in groupList" :key="item.id" :label="item.groupingName"
                               :value="item.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="IP类型">
                <el-select v-model="queryParams.ipType" clearable>
                    <el-option key="international" label="国际" value="international"></el-option>
                    <el-option key="china" label="国内" value="china"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="IP状态">
                <el-select v-model="queryParams.ipStatus" clearable>
                    <el-option v-for="(label, value) in getAllIpStatus()" :key="value"
                               :value="value" :label="label | extractTextFromSpan">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="创建日期">
                <el-date-picker
                    v-model="dateRange"
                    type="daterange"
                    value-format="yyyy-MM-dd"
                    placeholder="选择创建日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" :loading="loading" @click="getList">查询</el-button>
            </el-form-item>
        </el-form>
        <div>
            <el-button type="primary" size="mini" @click="ipGroupShow=true">分组管理</el-button>
            <el-button type="primary" :disabled="selectedIps.length<=0" size="mini" @click="ipGroupSelectShow=true">
                批量设置分组
            </el-button>
            <el-button type="primary" :disabled="selectedIps.length<=0" size="mini" @click="upgradeBandwidthShow=true">
                批量升级带宽
            </el-button>
            <el-button type="primary" :disabled="selectedIps.length<=0" size="mini" @click="productSelectShow=true">
                批量切换项目
            </el-button>
            <el-button type="primary" :disabled="selectedIps.length<=0" size="mini" @click="switchIpShow=true">
                批量切换IP
            </el-button>
<!--            <el-button type="primary" :disabled="selectedIps.length<=0" size="mini" @click="autoRenew(true)">-->
<!--                批量自动续费-->
<!--            </el-button>-->
<!--            <el-button type="primary" :disabled="selectedIps.length<=0" size="mini" @click="autoRenew(false)">-->
<!--                批量取消续费-->
<!--            </el-button>-->
            <el-button type="primary" :disabled="selectedIps.length<=0" size="mini" @click="renewShow=true">
                批量续费
            </el-button>
            <el-button type="primary" :disabled="selectedIps.length<=0" size="mini" @click="exportShow=true">
                批量导出
            </el-button>
        </div>

        <el-table
            v-loading="tableLoading"
            :data="list"
            border
            stripe
            max-height="800px"
            @selection-change="handleSelectionChange"
            style="width: 100%;margin-top: 20px">
            <el-table-column
                type="selection"
                align="center"
                fixed
                width="55">
            </el-table-column>
            <el-table-column
                prop="packageId"
                align="center"
                width="200px"
                label="套餐ID">
            </el-table-column>
            <el-table-column
                prop="IP"
                width="260px"
                align="left"
                label="IP">
                <template slot-scope="scope">
                    <div> IP：{{ scope.row.ip }}</div>
                    <div>HTTP：{{ scope.row.httpPort }}</div>
                    <div>SOCKS5：{{ scope.row.stock5Port }}</div>
                    <div>账号：{{ scope.row.accountNo }}</div>
                    <div>密码：{{ scope.row.pssword }}</div>
                </template>
            </el-table-column>
            <el-table-column
                prop="ipStatus"
                width="120px"
                align="center"
                label="IP状态">
                <template slot-scope="scope">
                    <div v-html="ipStatusFormat(scope.row.ipStatus)"></div>
                </template>
            </el-table-column>
            <el-table-column
                prop="ipType"
                align="left"
                width="200px"
                label="IP类型">
                <template slot-scope="scope">
                    <div v-for="item in scope.row.options">
                        {{ ipTypeOptionFormat(item.skuCode) }}：{{ item.optionName }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                prop="area"
                width="110px"
                align="center"
                label="地区">
            </el-table-column>
            <el-table-column
                prop="groupName"
                align="center"
                width="100px"
                label="分组">
            </el-table-column>
            <el-table-column
                prop="expireTime"
                width="200px"
                align="center"
                label="创建/到期时间">
                <template slot-scope="scope">
                    <div> {{ scope.row.createTime }}</div>
                    <div> {{ scope.row.expireTime }}</div>
                    <div> {{ calculateRemainingTime(scope.row.expireTime) }}</div>
                    <div>{{ scope.row.autoRenew ? '自动续费' : '' }}</div>
                </template>
            </el-table-column>
            <el-table-column
                prop="switchProjectNum"
                width="180px"
                align="center"
                label="项目剩余可切换次数">
                <template slot-scope="scope">
                    <div>{{ scope.row.switchProjectNum }}</div>
                    <el-link @click="getProjectSwitchRecord(scope.row.id)" type="primary">切换记录</el-link>
                </template>
            </el-table-column>
            <el-table-column
                prop="switchOperatorNum"
                width="180px"
                align="center"
                label="IP剩余可切换次数">
                <template slot-scope="scope">
                    <div>{{ scope.row.switchOperatorNum }}</div>
                    <el-link @click="getIpSwitchRecord(scope.row.id)" type="primary">切换记录</el-link>
                </template>
            </el-table-column>
        </el-table>
        <div style="text-align: center;margin: 20px">
            <el-pagination
                background
                layout="prev, pager, next  ,total, sizes"
                :page-sizes="[10, 20, 50, 100]"
                :page-size=queryParams.pageSize
                @current-change="changePage"
                @size-change="handleSizeChange"

                :total="total">
            </el-pagination>
        </div>

        <el-dialog
            title="切换IP"
            :visible.sync="switchIpShow"
            width="30%">
            <div>
                <SwitchIpForm v-if="switchIpShow" ref="switchIpForm"></SwitchIpForm>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="switchIpShow = false">取 消</el-button>
                <el-button type="primary" @click="doSwitchIp">确 定</el-button>
            </span>
        </el-dialog>

        <el-dialog
            title="ip分组"
            :visible.sync="ipGroupShow"
            width="60%">
            <div>
                <IpGroup v-if="ipGroupShow"></IpGroup>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="ipGroupShow = false">取 消</el-button>
                <el-button type="primary" @click="ipGroupShow = false">确 定</el-button>
            </span>
        </el-dialog>

        <el-dialog
            title="ip分组选择"
            :visible.sync="ipGroupSelectShow"
            width="30%">
            <div>
                <el-form>
                    <el-form-item label="分组选择">
                        <el-select v-model="selectedGroupId" @focus="getGroupList" clearable style="width: 100%">
                            <el-option v-for="item in groupList" :key="item.id" :label="item.groupingName"
                                       :value="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="ipGroupSelectShow = false">取 消</el-button>
                <el-button type="primary" :loading="loading" @click="submitSelectGroup">确 定</el-button>
            </span>
        </el-dialog>

        <el-dialog
            title="项目选择"
            :visible.sync="productSelectShow"
            width="30%">
            <div>
                <el-form>
                    <el-form-item label="项目选择">
                        <el-select v-model="selectedProductId" filterable clearable style="width: 100%">
                            <el-option v-for="item in productList" :key="item.id" :label="item.optionName"
                                       :value="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="productSelectShow = false">取 消</el-button>
                <el-button type="primary" :loading="loading" @click="submitSelectProduct">确 定</el-button>
            </span>
        </el-dialog>

        <el-dialog
            destroy-on-close
            title="续费"
            :visible.sync="renewShow"
            width="50%">
            <div>
                <TradeType ref="tradeType" v-if="renewShow" @close="renewShow=false" @pay="doRenew" @paySuccess="paySuccess">
                    <el-form style="width: 50%;margin: 0 auto" label-width="100px">
                        <el-form-item label="选择周期">
                            <el-select v-model="renewForm.selectedCycleId" clearable @change="computeIpRenew">
                                <el-option v-for="item in cycleList" :key="item.id" :label="item.optionName"
                                           :value="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="选择数量">
                            <el-input-number v-model="renewForm.num" :min="1"
                                             @change="computeIpRenew"></el-input-number>
                        </el-form-item>
                    </el-form>
                </TradeType>
            </div>
        </el-dialog>

        <el-dialog
            destroy-on-close
            title="带宽升级"
            :visible.sync="upgradeBandwidthShow"
            width="40%">
            <div>
                <TradeType v-if="upgradeBandwidthShow" ref="tradeTypeUpgradeBandwidth"
                           @close="upgradeBandwidthShow=false" @pay="doUpgradeBandwidth"
                           @paySuccess="paySuccess">
                    <el-form label-position="right" label-width="120">
                        <el-form-item label="选择带宽">
                            <el-select v-model="upgradeBandwidthForm.selectedOptionId" clearable>
                                <el-option v-for="item in upgradeBandwidthList" :key="item.id" :label="item.optionName"
                                           :value="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-form>
                </TradeType>
            </div>
        </el-dialog>

        <el-dialog
            destroy-on-close
            title="项目切换记录"
            :visible.sync="projectSwitchRecordShow"
            width="50%">
            <div>
                <el-table
                    :data="projectSwitchRecordList"
                    style="width: 100%">
                    <el-table-column
                        prop="oldProjectName"
                        label="原项目">
                    </el-table-column>
                    <el-table-column
                        prop="newProjectName"
                        label="新项目">
                    </el-table-column>
                    <el-table-column
                        prop="createTime"
                        label="切换时间">
                    </el-table-column>
                </el-table>
            </div>
        </el-dialog>

        <el-dialog
            destroy-on-close
            title="选择导出格式"
            :visible.sync="exportShow"
            width="30%">
            <div>
                <el-divider content-position="left">选择协议</el-divider>
                <el-radio-group v-model="portType" class="export-radio-group">
                    <el-radio label="socks5" class="radio-item">
                        SOCKS5
                    </el-radio>
                    <el-radio label="http" class="radio-item">
                        HTTP
                    </el-radio>
                </el-radio-group>
                <el-divider content-position="left">选择格式</el-divider>
                <el-radio-group v-model="exportType" class="export-radio-group">
                    <el-radio label="laoliu" class="radio-item">
                        有米格式:ip:端口 账号 密码
                    </el-radio>
                    <el-radio label="wanan" class="radio-item">
                        万安格式:ip|端口|账号|密码|到期日期
                    </el-radio>
                    <el-radio label="xiegang" class="radio-item">
                        斜杠格式:ip/端口/账号/密码
                    </el-radio>
                    <el-radio label="diqu" class="radio-item">
                        地区格式:ip/端口/账号/密码/地区
                    </el-radio>
                    <el-radio label="diqu_date" class="radio-item">
                        地区日期格式:ip/端口/账号/密码/地区/购买/到期
                    </el-radio>
                    <el-radio label="liulanqi" class="radio-item">
                        浏览器格式:ip:端口:账号:密码
                    </el-radio>
                </el-radio-group>
                <div style="text-align: right">
                    <el-button type="primary" :loading="loading" @click="doExport">确 定</el-button>
                </div>
            </div>
        </el-dialog>

        <el-dialog
            destroy-on-close
            title="IP切换记录"
            :visible.sync="iPSwitchRecordShow"
            width="50%">
            <div>
                <el-table
                    :data="iPSwitchRecordList"
                    style="width: 100%">
                    <el-table-column
                        prop="oldIp"
                        label="原IP">
                    </el-table-column>
                    <el-table-column
                        prop="newIp"
                        label="新IP">
                    </el-table-column>
                    <el-table-column
                        prop="oldOperator"
                        label="原运营商">
                    </el-table-column>
                    <el-table-column
                        prop="newOperator"
                        label="新运营商">
                    </el-table-column>
                    <el-table-column
                        prop="oldArea"
                        label="原地区">
                    </el-table-column>
                    <el-table-column
                        prop="newArea"
                        label="新地区">
                    </el-table-column>
                    <el-table-column
                        prop="switchMethod"
                        label="切换方式">
                    </el-table-column>
                    <el-table-column
                        prop="createTime"
                        label="切换时间">
                    </el-table-column>
                </el-table>
            </div>
        </el-dialog>
    </div>
</template>
<script>

import {addDateRange, getAllIpStatus, ipStatusFormat, ipTypeOptionFormat, tansParams,} from "@/api/utils";
import IpGroup from "@/components/IpGroup.vue";
import TradeType from "@/components/TradeTypeSelect.vue";
import {EventBus} from "@/api/eventBus";
import SwitchIpForm from "@/components/SwitchIpForm.vue";

export default {
    name: 'IpList',
    props:{
        packageId:undefined
    },
    data() {
        return {
            loading: false,
            tableLoading: false,
            switchIpShow: false,
            renewShow: false,
            exportShow: false,
            ipGroupShow: false,
            projectSwitchRecordShow: false,
            iPSwitchRecordShow: false,
            ipGroupSelectShow: false,
            upgradeBandwidthShow: false,
            selectedGroupId: undefined,
            selectedProductId: undefined,
            productSelectShow: undefined,
            upgradeBandwidthForm: {
                selectedOptionId: undefined,
                num: 1,
            },
            renewForm: {
                selectedCycleId: undefined,
                num: 1,
            },
            timeRange: [],
            dateRange: [],
            cycleList: [],
            list: [],
            selectedIps: [],
            groupList: [],
            productList: [],
            upgradeBandwidthList: [],
            projectSwitchRecordList: [],
            iPSwitchRecordList: [],
            total: 0,
            portType:'stock5',
            exportType:'laoliu',
            queryParams: {
                pageSize: 10,
                pageNum: 1,
                groupId: '',
                packageId: '',
                ip: '',
                ipType: '',
                area: '',
                ipStatus: '',
            }
        };
    },
    components: {SwitchIpForm, TradeType, IpGroup},
    created() {
        console.log('accountInfo create...')
        this.queryParams.packageId = this.packageId
        this.getList()
        this.getGroupList()
        EventBus.$on('refreshOrderList',()=>{
            this.queryParams.pageNum = 1
            this.getList()
        });
    },
    watch: {
        renewShow: function (val) {
            if (val) {
                this.getAllCycle()
            }
            this.renewForm.selectedCycleId = undefined
        },
        productSelectShow: function (val) {
            if (val) {
                this.getAllProduct()
            }
        },
        //监听：upgradeBandwidthForm.selectedOptionId
        'upgradeBandwidthForm.selectedOptionId':function (val){
            console.log('val==>',val)
            if(val){
                this.getLoanUpgradePrice()
            }else{

            }
        },
        upgradeBandwidthShow: function (val) {
            if (val) {
                this.getUpgradeBandwidthList()
            }
            this.upgradeBandwidthForm.selectedOptionId = undefined
        },
    },
    beforeDestroy() {
        console.log('accountInfo beforeDestroy...')
    },
    destroyed() {
        console.log('accountInfo destroyed...')
    },
    methods: {
        getAllIpStatus,
        ipTypeOptionFormat,
        ipStatusFormat,
        handleBuyIp() {
            EventBus.$emit('openBuyIp');
        },
        handleSelectionChange(val) {
            this.selectedIps = val
        },
        doUpgradeBandwidth(tradeType) {
            this.$http.post('/feiyu/ip/upgradeBandwidth', {
                idList: this.selectedIps.map(item => item.id),
                tradeType: tradeType,
                optionId: this.upgradeBandwidthForm.selectedOptionId,
            }).then(res => {
                this.$message.success('下单成功,请及时支付')
                this.$refs.tradeTypeUpgradeBandwidth.generateQRCode(res.data.codeUrl)
                this.$refs.tradeTypeUpgradeBandwidth.amt = res.data.totalPrice
                this.$refs.tradeTypeUpgradeBandwidth.loading = false
                this.$refs.tradeTypeUpgradeBandwidth.startInterval(res.data.orderId)
            }).catch(err => {
                this.$message.error(err.msg)
            }).finally(() => {
                this.$refs.tradeTypeUpgradeBandwidth.loading = false
            })
        },
        doRenew(tradeType) {
            if (!this.renewForm.selectedCycleId) {
                this.$message.error('请选择续费周期')
                this.$refs.tradeType.loading = false
                this.$refs.tradeType.querying = false
                return
            }
            if (!this.renewForm.num) {
                this.$message.error('请选择续费周期数量')
                this.$refs.tradeType.loading = false
                this.$refs.tradeType.querying = false
                return
            }
            this.loading = true
            this.$http.post('/feiyu/ip/IpRenew', {
                ids: this.selectedIps.map(item => item.id),
                tradeType: tradeType,
                cycleOptionId: this.renewForm.selectedCycleId,
                cycleNum: this.renewForm.num,
            }).then(res => {
                this.$message.success('下单成功,请及时支付')
                this.$refs.tradeType.generateQRCode(res.data.codeUrl)
                this.$refs.tradeType.amt = res.data.totalPrice
                this.$refs.tradeType.loading = false
                this.$refs.tradeType.startInterval(res.data.orderId)
            }).finally(() => {
                this.loading = false
            })
        },
        autoRenew(autoRenew) {
            this.$http.post('/feiyu/ip/changeAutoRenew', {
                ids: this.selectedIps.map(item => item.id),
                autoRenew: autoRenew,
            }).then(res => {
                this.$message.success('设置成功')
                this.getList()
            }).catch(err => {
                this.$message.error(err.msg)
            })
        },
        //计算贷款升级所需费用
        getLoanUpgradePrice(){
            this.$http.post('/feiyu/ip/computeUpgradeBandwidth',{
                idList: this.selectedIps.map(item => item.id),
                optionId:this.upgradeBandwidthForm.selectedOptionId
            }).then(res=>{
                this.$refs.tradeTypeUpgradeBandwidth.amt = res.data
            })
        },

        getUpgradeBandwidthList() {
            this.$http.get('/feiyu/ip/queryUpgradeBandwidthList').then(res => {
                this.upgradeBandwidthList = res.data
            })
        },
        paySuccess() {
            this.$message.success('支付成功')
            this.getList()
            this.upgradeBandwidthShow = false
        },
        getAllCycle() {
            this.$http.get('/feiyu/goods/getAllCycle').then(res => {
                this.cycleList = res.data
            })
        },
        submitSelectGroup() {
            if (!this.selectedGroupId) {
                this.$message.error('请选择分组')
                return
            }
            this.loading = true
            this.$http.post('/feiyu/ipGrouping/batchGrouping', {
                ids: this.selectedIps.map(item => item.id),
                groupingId: this.selectedGroupId
            }).then(res => {
                this.$message.success('修改成功')
                this.ipGroupSelectShow = false
                this.getList()
            }).catch(err => {
                this.$message.error(err.msg)
            }).finally(() => {
                this.loading = false
            })
        },
        getGroupList() {
            this.$http.get('/feiyu/ipGrouping/list?pageNum=' + 100)
                .then(res => {
                    this.groupList = res.rows
                }).catch(err => {
                this.$message.error(err.msg)
            }).finally(() => {
                this.loading = false
            })
        },
        computeIpRenew() {
            if (!this.renewForm.selectedCycleId || !this.renewForm.num) {
                return
            }
            this.$http.post('/feiyu/ip/computeIpRenew', {
                ids: this.selectedIps.map(item => item.id),
                tradeType: 'ZFB_NATIVE',
                cycleOptionId: this.renewForm.selectedCycleId,
                cycleNum: this.renewForm.num,
            }).then(res => {
                this.$refs.tradeType.amt = res.data
            })
        },
        changePage(page) {
            this.queryParams.pageNum = page
            this.getList()
        },
        handleSizeChange(val) {
            this.queryParams.pageSize = val;
            this.getList()
        },
        getAllProduct() {
            this.$http.get('/feiyu/goods/getAllProject')
                .then(res => {
                    this.productList = res.data
                }).catch(err => {
                this.$message.error(err.msg)
            }).finally(() => {
                this.loading = false
            })
        },
        submitSelectProduct() {
            if (!this.selectedProductId) {
                this.$message.error('请选择产品')
                return
            }
            this.loading = true
            this.$http.post('/feiyu/ip/switchGames', {
                ipIdList: this.selectedIps.map(item => item.id),
                projectId: this.selectedProductId
            }).then(res => {
                this.$alert(`切换成功${res.data.isSuccessNum}次，失败${res.data.failNum}次`, '操作成功', {
                    confirmButtonText: '确定',
                });
                this.productSelectShow = false
                this.getList()
            }).catch(err => {
                this.$message.error(err.msg)
            }).finally(() => {
                this.loading = false
            })
        },
        getList() {
            this.tableLoading = true
            this.$http.get('/feiyu/ip/list?' + tansParams(addDateRange(this.queryParams, this.dateRange)))
                .then(res => {
                    this.list = res.rows
                    this.total = res.total
                }).catch(err => {
                this.$message.error(err.msg)
            }).finally(() => {
                this.tableLoading = false
            })
        },
        calculateRemainingTime(expirationTime) {
            const expirationDate = new Date(expirationTime);
            const currentDate = new Date();

            const timeDifference = expirationDate.getTime() - currentDate.getTime();
            const daysLeft = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
            const hoursLeft = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));

            if (daysLeft > 0) {
                return `剩余 ${daysLeft} 天`;
            } else {
                return `剩余 ${hoursLeft} 小时`;
            }
        },
        getProjectSwitchRecord(id) {
            this.$http.get('/feiyu/ip/switchProjectList?ipId='+id).then(res => {
                this.projectSwitchRecordList = res.rows
                this.projectSwitchRecordShow = true
            })
        },
        getIpSwitchRecord(id) {
            this.$http.get('/feiyu/ip/switchIpList?ipId='+id).then(res => {
                this.iPSwitchRecordList = res.rows
                this.iPSwitchRecordShow = true
            })
        },
        doExport() {
            this.loading = true
            this.$http.post('/feiyu/ip/export/exportTxt', {
                formatType: this.exportType,
                portType: this.portType,
                ids: this.selectedIps.map(item => item.id)
            }).then(response => {
                console.log('response',response)
                // 创建 Blob 对象
                const blob = new Blob([response.data], { type: 'text/plain' })
                // 创建下载链接
                const url = window.URL.createObjectURL(blob)
                const link = document.createElement('a')
                link.style.display = 'none'
                link.href = url
                link.download = 'ip_export.txt'

                document.body.appendChild(link)
                link.click()

                // 清理
                window.URL.revokeObjectURL(url)
                document.body.removeChild(link)
                this.exportShow = false
            }).catch(err => {
                this.$message.error(err.msg)
            }).finally(() => {
                this.loading = false
            })
        },
        doSwitchIp() {
            this.$refs.switchIpForm.validate(valid => {
                if (valid) {
                    let requestParams = Object.assign(this.$refs.switchIpForm.form,{
                        ipIds: this.selectedIps.map(item => item.id)
                    })
                    this.loading = true
                    this.$http.post('/feiyu/ip/switchIp', requestParams).then(res => {
                        this.$message.success('切换成功')
                        this.getList()
                    }).catch(err => {
                        this.$message.error(err.msg)
                    }).finally(() => {
                        this.loading = false
                        this.switchIpShow = false
                    })
                }
            })
        }
    }
}
</script>

<style scoped>
.export-radio-group {
    display: flex;
    flex-direction: column;
    padding: 10px;
}

.radio-item {
    margin-bottom: 15px;
    padding: 8px;
}

.radio-item:last-child {
    margin-bottom: 0;
}
</style>
