import axios from 'axios';
import { Notification, MessageBox, Message, Loading } from 'element-ui'
const instance = axios.create({
    baseURL: process.env.VUE_APP_BASE_API,
    timeout: 10000,
    // 其他配置...
});

instance.interceptors.request.use(function (config) {
    // 可以通过headers，查看+设置请求头
    // config.headers['info'] = 'itheima666'
    // 每次发送请求，都会执行这个回调函数
    // console.log(config)
    // 在发送请求之前做些什么,比如: 统一设置token
    const token = localStorage.getItem('token')
    // token存在，才携带
    if (token) {
        config.headers['Authorization'] = token
    }
    return config;
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});

// 添加响应拦截器
instance.interceptors.response.use(function (response) {

    if(response.headers['content-type']==='text/plain;charset=UTF-8'){
        console.log('response:::',response.headers['content-type'])
        return response;
    }
    // 2xx 范围内的状态码都会触发该函数。
    // 对响应数据做点什么，比如: 数据剥离
    if(response.data.code === 401){
        localStorage.removeItem('token')
        localStorage.removeItem('userInfo')
        MessageBox.confirm('登录状态已过期，您可以继续留在该页面，或者重新登录', '系统提示', { confirmButtonText: '重新登录', cancelButtonText: '取消', type: 'warning' }).then(() => {
            location.href = '/'
        }).catch(() => {
        });
    }
    if(response.data.code !== 200){
        return Promise.reject(response.data);
    }
    return response.data;
}, function (error) {
    // 超出 2xx 范围的状态码都会触发该函数。
    // 对响应错误做点什么: 比如统一处理token失效
    return Promise.reject(error);
});

export default instance;
