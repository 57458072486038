<template>
    <div class="main" v-loading="loading">
        <div class="item-main">
            <div class="title">类型</div>
            <template v-for="(item,index) in goodsClassify">
                <div class="item">
                     <span @click="selectedClassify(index)" class="btn"
                           :class="{ 'btn-active': item.id === currentGoodsClassify.id }">{{ item.goodsName }}</span>
                </div>
            </template>
        </div>
        <div style="height: 20px"></div>
        <template v-for="item in goodsSku">
            <div v-if="item.skuCode === 'PROJECT'" class="item-main">
                <div class="title" style="display: flex; justify-content: space-between; align-items: center;">
                    <span>{{ item.skuName }}</span>
                    <el-input
                        v-model="searchQuery"
                        placeholder="搜索项目"
                        size="small"
                        style="width: 200px"
                    ></el-input>
                </div>
                <div class="item" v-if="filteredOptions.length > 0" style="display: flex; justify-content: center; align-items: center; flex-wrap: wrap; max-height: 200px; overflow-y: auto;">
                    <template v-for="option in filteredOptions">
                        <span @click="selectedOption(option,item.options)" class="btn"
                              :class="{ 'btn-active': option.selected }"
                              :title="option.optionName"
                              style="width: 10%; text-align: center;">
                            <span style="display: block; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">{{ option.optionName }}</span>
                        </span>
                    </template>
                </div>
                <div class="item" v-else>
                    <span style="margin: 10px">暂无匹配结果</span>
                </div>
            </div>
            <div v-else class="item-main">
                <div class="title">{{ item.skuName }}</div>
                <div class="item" v-if="item.options.length > 0">
                    <template v-for="option in item.options">
                        <span @click="selectedOption(option,item.options)" class="btn"
                              :class="{ 'btn-active': option.selected }">
                            <span>{{ option.optionName }}</span>
                            <span v-show="option.totalIpNum"
                                  style="font-size: 12px">(库存：{{ option.totalIpNum }})</span>
                        </span>
                    </template>
                </div>
                <div class="item" v-else>
                    <span style="margin: 10px">暂无可用</span>
                </div>
            </div>
            <div style="height: 20px"></div>
        </template>
        <div class="item-main">
            <div class="title">购买时长</div>
            <el-input-number size="small" v-model="cycleNum" :min="1" :max="1000"></el-input-number>
            <span style="margin-left: 8px">({{currentCycleName}})</span>
        </div>
        <div style="height: 20px"></div>
        <div class="item-main">
            <div class="title">购买数量</div>
            <el-input-number size="small" v-model="buyIpNum" :min="0" :max="ipStock"></el-input-number>
            <span style="margin-left: 8px">(库存：{{ ipStock }})</span>
        </div>
        <div style="height: 20px"></div>
        <div class="item-main">
            <h3>订单汇总</h3>
            <div style="display: flex;flex-direction: row;justify-content: space-between">
                <div>
<!--                    <el-checkbox v-model="autoRenew">自动续费</el-checkbox>-->
                    <div style="margin-top: 10px">购买项目：<span
                        style="font-size: 16px">{{ currentProjectSkuOption.optionName }}</span></div>
                </div>
                <div style="text-align: right">
                    <div>总数量：{{ buyIpNum }} 个</div>
                    <div style="margin-top: 10px">购买时长：{{ cycleNum }} {{ currentCycleName }}</div>
                    <div style="height: 10px"></div>
                    <el-button type="primary" size="small" :disabled="totalPrice<=0" round @click="tradeSelectShow=true">实付¥{{ totalPrice }}
                    </el-button>
                </div>
            </div>
        </div>
        <el-dialog
            destroy-on-close
            title="选择支付方式"
            append-to-body
            :visible.sync="tradeSelectShow">
            <div>
                <TradeType v-if="tradeSelectShow" ref="tradeType" @pay="doPay" @close="tradeSelectShow=false" @paySuccess="paySuccess">

                </TradeType>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import TradeType from "@/components/TradeTypeSelect.vue";
import {EventBus} from "@/api/eventBus";

export default {
    name: 'BuyIp',
    components: {TradeType},
    data() {
        return {
            loading: false,
            tradeSelectShow: false,
            currentGoodsClassifyIndex: 0,
            goodsClassify: [],
            goodsSku: [],
            projectList: [],
            currentSkuId: undefined,
            cycleNum: 1,
            buyIpNum: 0,
            totalPrice: 0,
            autoRenew: false,
            tradeTypeReady: false,
            searchQuery: '',
        }
    },
    created() {
        this.getGoodsClassify()
    },
    watch:{
        currentGoodsClassify: {
            handler(newVal, oldVal) {
                this.getAvailableIp()
            },
        },
        totalPrice:{
            handler(newVal, oldVal) {
                this.$emit('totalPrice',newVal)
            },
            immediate: true
        },
        operatorSkuOptionCode: {
            handler(newVal, oldVal) {
                this.getAvailableIp()
            },
            // immediate: true
        },
        projectSkuOptionId: {
            handler(newVal, oldVal) {
                this.getAvailableIp()
            },
            // immediate: true
        },
        buyIpNum:{
            handler(newVal, oldVal) {
                this.getOrderPrice()
            },
        },
        cycleNum:{
            handler(newVal, oldVal) {
                this.getOrderPrice()
            },
        }
    },
    computed: {
        userInfo: function () {
            let userInfoStr = localStorage.getItem('userInfo');
            if (!userInfoStr) {
                return {}
            }
            return JSON.parse(userInfoStr)
        },
        currentGoodsClassify: function () {
            if (this.goodsClassify.length > 0) {
                return this.goodsClassify[this.currentGoodsClassifyIndex]
            }
            return {}
        },
        //所选运营商
        operatorSkuOptionCode: function () {
            if (!this.goodsSku.find(v => v.skuCode === 'OPERATOR')) {
                return ''
            }
            return this.goodsSku.find(v => v.skuCode === 'OPERATOR').options.find(v => v.selected).optionValue
        },
        //所选项目id
        currentProjectSkuOption: function () {
            if (!this.goodsSku.find(v => v.skuCode === 'PROJECT')) {
                return {}
            }
            return this.goodsSku.find(v => v.skuCode === 'PROJECT').options.find(v => v.selected)
        },
        ipStock: function () {
            let o = this.goodsSku.find(v => v.skuCode === 'AREA')
            if (!o) {
                return 0
            }
            let item = o.options.find(v => v.selected)
            if (item) {
                if(item.totalIpNum > 0){
                    this.buyIpNum = 1
                }
                return item.totalIpNum
            }
            return 0
        },
        currentCycleName: function () {
            if (!this.goodsSku.find(v => v.skuCode === 'CYCLE')) {
                return {}
            }
            let cycle = this.goodsSku.find(v => v.skuCode === 'CYCLE').options.find(v => v.selected)
            return cycle.optionName.replace('按', '')
        },
       /* totalPrice: function () {
            if(!this.ipStock){
                return 0
            }
            let price = this.goodsSku.flatMap(obj => obj.options.filter(option => {
                return option.selected && option.skuCode !== 'AREA'
            })
                .map(option => Number(option.floatPrice)))
                .reduce((acc, curr) => acc + curr, 0);
            if (this.currentGoodsClassify) {
                price = price + Number(this.currentGoodsClassify.price)
            }
            return Number(price * this.cycleNum * this.buyIpNum).toFixed(2)
        },*/
        selectedOptionIds: function () {
            return this.goodsSku.flatMap(obj => obj.options.filter(option => {
                return option.selected && option.skuCode !== 'AREA'
            }).map(option => option.id));
        },
        //所选项目id
        projectSkuOptionId: function () {
            let o = this.goodsSku.find(v => v.skuCode === 'PROJECT')
            if (!o) {
                return ''
            }
            let a = o.options.find(v => v.selected)
            if(a){
                return a.id;
            }
            return ''
        },
        filteredOptions() {
            let item = this.goodsSku.find(v => v.skuCode === 'PROJECT')
            console.log('item:',item)
            if (!item || !item.options) {
                return [];
            }
            // 如果搜索框为空，或者只输入了空格，返回所有选项
            if (!this.searchQuery || !this.searchQuery.trim()) {
                return item.options;
            }
            const query = this.searchQuery.trim().toLowerCase();
            return item.options.filter(option =>
                option.optionName.toLowerCase().includes(query)
            );
        }
    },
    methods: {
        doPay(tradeType) {
            const areaOptions = this.goodsSku.find(v => v.skuCode === 'AREA').options
            const area = areaOptions.find(v => v.selected).optionName
            this.$http.post('/feiyu/order/createOrder', {
                tradeType: tradeType,
                ipType: this.currentGoodsClassify.goodsCode,
                area: area,
                cycleNum: this.cycleNum,
                buyIpNum: this.buyIpNum,
                autoRenew: this.autoRenew,
                optionIds: this.selectedOptionIds
            }).then(res => {
                this.$message.success('下单成功,请及时支付')
                this.$refs.tradeType.generateQRCode(res.data.codeUrl)
                this.$refs.tradeType.amt = res.data.totalPrice
                this.$refs.tradeType.loading = false
                this.$refs.tradeType.startInterval(res.data.orderId)
            }).catch(err => {
                this.$message.error(err.msg)
            }).finally(() => {
                this.$refs.tradeType.loading = false
            })
        },
        paySuccess() {
            this.$message.success('支付成功')
            this.tradeSelectShow = false
            EventBus.$emit('closeBuy')
            EventBus.$emit('refreshOrderList')
            //弹窗loading

            //跳转到个人中心
            // this.$router.push(
            //     {
            //         path: '/center', query: {menuName:'rechargeRecord'}
            //     }
            // )
        },
        selectedClassify(index) {
            this.currentGoodsClassifyIndex = index
        },
        selectedOption(option, options) {
            options.forEach(o => {
                o.selected = o.id === option.id;
            })
            this.getOrderPrice()
        },
        getAvailableIp() {
            if(this.operatorSkuOptionCode && this.projectSkuOptionId && this.currentGoodsClassify){
                this.$http.get('/feiyu/goods/getAvailableIp?operator='
                    + this.operatorSkuOptionCode
                    + '&projectId=' + this.projectSkuOptionId
                    + '&ipType=' + this.currentGoodsClassify.goodsCode
                    + '&memberId=' +  (this.userInfo.id || '')
                ).then(res => {
                    this.goodsSku.find(v => v.skuCode === 'AREA').options = res.data.map((item, index) => {
                        return {
                            id: item.area,
                            optionName: item.area,
                            totalIpNum: item.totalIpNum,
                            selected: index === 0,
                            skuCode: 'AREA',
                            floatPrice: 0
                        }
                    })
                    this.getOrderPrice()
                }).finally(() => {
                    this.loading = false
                })
            }
        },
        getGoodsClassify() {
            this.$http.get('/feiyu/goods/getGoodsClassify')
                .then(res => {
                    this.goodsClassify = res.data
                    this.getGoodsSku()
                })
        },
        getGoodsSku() {
            this.loading = true
            this.$http.get('/feiyu/goods/getGoodsSku?goodsId=' + this.currentGoodsClassify.id).then(res => {
                let result = res.data
                result.forEach(item => {
                    if (item.options) {
                        for (let i = 0; i < item.options.length; i++) {
                            item.options[i].selected = (i === 0)
                        }
                    }
                })
                this.goodsSku = result
                this.getAvailableIp()
            })
        },
        getOrderPrice(){
            const areaOptions = this.goodsSku.find(v => v.skuCode === 'AREA').options
            const area = areaOptions.find(v => v.selected).optionName
            if(!this.buyIpNum){
                this.totalPrice = 0
                return
            }
            if(!this.cycleNum || !this.selectedOptionIds){
                return
            }
            this.$http.post('/feiyu/order/computeOrder',{
                tradeType:'ALI_NATIVE',
                ipType: this.currentGoodsClassify.goodsCode,
                area: area,
                cycleNum: this.cycleNum,
                buyIpNum: this.buyIpNum,
                autoRenew: this.autoRenew,
                optionIds: this.selectedOptionIds
            }).then(res => {
                this.totalPrice = res.data.totalPrice
            })
        }
    }
}
</script>

<style scoped>
.main {
    max-height: 800px;
    overflow-y: scroll;
}

.item-main {
    background-color: #ECF5FF;
    padding: 10px;
    border-radius: 10px;
}

.title {
    margin: 10px 10px;
}

.item {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    flex-direction: row;
}

.btn {
    border: 1px solid #7687F9;
    color: #7687F9;
    padding: 8px 16px;
    border-radius: 20px;
    cursor: pointer;
}

.btn-active {
    background-color: #7687F9;
    color: #fff;
}
</style>
