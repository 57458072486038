<template>
    <div>
        <h1>代理管理</h1>
        <el-row :gutter="12">
            <el-col :span="24">
                <el-card shadow="hover">
                    <div>代理余额</div>
                    <div style="margin: 10px 0;font-size: 20px;font-weight: bold">{{ proxyInfo.balance }}</div>
                    <el-button size="mini" type="primary" @click="handleShowInviteLine">邀请链接</el-button>
                    <el-button size="mini" type="primary" @click="bindCardShow = true">绑卡</el-button>
                </el-card>
            </el-col>
        </el-row>
        <div style="height: 10px"></div>
        <el-row :gutter="12">
            <el-col :span="12">
                <el-card shadow="hover">
                    <h3>银行卡列表</h3>
                    <el-table
                        :data="bankList"
                        border
                        size="mini"
                        max-height="500px"
                        style="width: 100%">
                        <el-table-column
                            prop="realName"
                            align="center"
                            width="120"
                            label="姓名">
                        </el-table-column>
                        <el-table-column
                            prop="bankNo"
                            label="卡号">
                        </el-table-column>
                        <el-table-column
                            prop="bankName"
                            align="center"
                            width="100"
                            label="名称">
                        </el-table-column>
                        <el-table-column
                            fixed="right"
                            width="100"
                            align="center"
                            label="操作">
                            <template slot-scope="scope">
                                <el-button size="mini" type="text" @click="handleWithdraw(scope.row)">提现
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div style="text-align: center">
                        <el-pagination
                            v-show="bankTotal>0"
                            :page.sync="bankListQuery.pageNum"
                            :limit.sync="bankListQuery.pageSize"
                            @current-change="getBankList"
                            layout="prev, pager, next"
                            :total="bankTotal">
                        </el-pagination>
                    </div>
                </el-card>
            </el-col>
            <el-col :span="12">
                <el-card shadow="hover">
                    <h3>推广收益明细</h3>
                    <el-table
                        :data="incomeList"
                        border
                        size="mini"
                        max-height="500px"
                        style="width: 100%">
                        <el-table-column
                            prop="orderAmt"
                            align="right"
                            label="订单金额">
                        </el-table-column>
                        <el-table-column
                            prop="incomeAmt"
                            align="right"
                            label="推广收益">
                            <template slot-scope="scope">
                                {{ scope.row.incomeAmt }}({{ scope.row.proxyRate }})
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="createTime"
                            align="center"
                            label="收益时间">
                        </el-table-column>
                    </el-table>
                    <div style="text-align: center">
                        <el-pagination
                            v-show="incomeTotal>0"
                            :page.sync="incomeQuery.pageNum"
                            :limit.sync="incomeQuery.pageSize"
                            @current-change="getIncomeList"
                            layout="prev, pager, next"
                            :total="incomeTotal">
                        </el-pagination>
                    </div>
                </el-card>
            </el-col>
        </el-row>
        <div style="height: 10px"></div>
        <el-row :gutter="12">
            <el-col :span="12">
                <el-card shadow="hover">
                    <h3>邀请人列表</h3>
                    <el-table
                        :data="inviteList"
                        border
                        size="mini"
                        max-height="500px"
                        style="width: 100%">
                        <el-table-column
                            prop="realName"
                            align="left"
                            label="姓名">
                        </el-table-column>
                        <el-table-column
                            prop="phone"
                            align="right"
                            label="手机号">
                        </el-table-column>
                        <el-table-column
                            prop="discount"
                            align="center"
                            label="折扣">
                        </el-table-column>
                        <el-table-column
                            fixed="right"
                            align="center"
                            width="100"
                            label="操作">
                            <template slot-scope="scope">
                                <el-button size="mini" type="text" @click="handleSetDiscount(scope.row)">折扣设置
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div style="text-align: center">
                        <el-pagination
                            v-show="inviteTotal>0"
                            :page.sync="inviteQuery.pageNum"
                            :limit.sync="inviteQuery.pageSize"
                            @current-change="getInviteList"
                            layout="prev, pager, next"
                            :total="inviteTotal">
                        </el-pagination>
                    </div>
                </el-card>
            </el-col>
            <el-col :span="12">
                <el-card shadow="hover">
                    <h3>提现订单</h3>
                    <el-table
                        :data="withdrawList"
                        border
                        size="mini"
                        max-height="500px"
                        style="width: 100%">
                        <el-table-column
                            prop="withdrawAmt"
                            align="right"
                            label="提现金额">
                        </el-table-column>
                        <el-table-column
                            prop="bankNo"
                            align="left"
                            width="160"
                            label="卡号">
                        </el-table-column>
                        <el-table-column
                            prop="withdrawStatus"
                            align="center"
                            label="状态">
                            <template slot-scope="scope">
                                <!--    SUCCESS,FAIL,WAIT,PROCESSING  -->
                                <template v-if="scope.row.withdrawStatus==='SUCCESS'">
                                    <el-tag size="mini" type="success">成功</el-tag>
                                </template>
                                <template v-if="scope.row.withdrawStatus==='FAIL'">
                                    <el-tag size="mini"  type="danger">失败</el-tag>
                                </template>
                                <template v-if="scope.row.withdrawStatus==='WAIT'">
                                    <el-tag size="mini"  type="warning">待审核</el-tag>
                                </template>
                                <template v-if="scope.row.withdrawStatus==='PROCESSING'">
                                    <el-tag size="mini"  type="info">处理中</el-tag>
                                </template>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="createTime"
                            align="center"
                            label="发起时间">
                        </el-table-column>
                    </el-table>
                    <div style="text-align: center">
                        <el-pagination
                            v-show="inviteTotal>0"
                            :page.sync="inviteQuery.pageNum"
                            :limit.sync="inviteQuery.pageSize"
                            @current-change="getInviteList"
                            layout="prev, pager, next"
                            :total="inviteTotal">
                        </el-pagination>
                    </div>
                </el-card>
            </el-col>
        </el-row>

        <el-dialog
            title="绑卡"
            :visible.sync="bindCardShow"
            width="30%">
            <div>
                <el-form :model="bindCardForm" status-icon ref="bindCardForm" label-width="100px" class="demo-ruleForm">
                    <el-form-item :label="bankTypeLabel" prop="bankNo">
                        <el-input v-model="bindCardForm.bankNo" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="银行类型" prop="bankType">
                        <el-select v-model="bindCardForm.bankType" placeholder="请选择银行类型">
                            <el-option label="银行卡" value="BANK"></el-option>
                            <el-option label="支付宝" value="ALI"></el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="bindCardShow = false">取 消</el-button>
                <el-button type="primary" @click="bindBankCard">确 定</el-button>
          </span>
        </el-dialog>
    </div>
</template>

<script>


import {tansParams} from "@/api/utils";

export default {
    name: 'ProxyModule',
    components: {},
    computed: {
        // proxyInfo: function () {
        //     let userInfoStr = localStorage.getItem('proxyInfo');
        //     if (!userInfoStr) {
        //         return {}
        //     }
        //     return JSON.parse(userInfoStr)
        // }
    },
    //watch bindCardForm.bankType 监听，bankTypeLabel赋值
    watch: {
        'bindCardForm.bankType': function (val) {
            if (val === 'ALI') {
                this.bankTypeLabel = '支付宝账号'
            } else {
                this.bankTypeLabel = '银行卡号'
            }
        }
    },
    created() {
        this.getProxyInfo()
        this.getBankList()
        this.getIncomeList()
        this.getInviteList()
        this.getWithdrawOrder()
    },
    data() {
        return {
            proxyInfo:{},
            bindCardShow: false,
            bankTypeLabel: '银行卡号',
            bankList: [],
            incomeList: [],
            inviteList: [],
            withdrawList: [],
            bankTotal: 0,
            incomeTotal: 0,
            inviteTotal: 0,
            withdrawTotal: 0,
            bankListQuery: {
                pageSize: 10,
                pageNum: 1,
            },
            incomeQuery: {
                pageSize: 10,
                pageNum: 1,
            },
            inviteQuery: {
                pageSize: 10,
                pageNum: 1,
            },
            withdrawQuery: {
                pageSize: 10,
                pageNum: 1,
            },
            bindCardForm: {
                bankNo: '',
                bankType: 'BANK'
            }
        };
    },
    methods: {
        //代理绑卡 post提交，参数:bankNo,bankType
        bindBankCard() {
            this.$http.post('/feiyu/proxy/bindBank?' + tansParams(this.bindCardForm))
                .then(res => {
                    this.$message.success('绑卡成功')
                    this.bindCardShow = false
                    this.getBankList()
                }).catch(err => {
                this.$message.error(err.msg)
            })
        },
        //银行卡列表
        getBankList() {
            this.$http.get('/feiyu/proxy/bankList?' + tansParams(this.bankListQuery))
                .then(res => {
                    this.bankList = res.rows
                    this.bankTotal = res.total
                }).catch(err => {
                this.$message.error(err.msg)
            })
        },
        //获取推广收益列表
        getIncomeList() {
            this.$http.get('/feiyu/proxy/queryProxyIncome?' + tansParams(this.incomeQuery))
                .then(res => {
                    this.incomeList = res.rows
                    this.incomeTotal = res.total
                }).catch(err => {
                this.$message.error(err.msg)
            })
        },
        //获取邀请会员列表
        getInviteList() {
            this.$http.get('/feiyu/proxy/inviteMemberList?' + tansParams(this.inviteQuery))
                .then(res => {
                    this.inviteList = res.rows
                    this.inviteTotal = res.total
                }).catch(err => {
                this.$message.error(err.msg)
            })
        },
        //获取提现订单
        getWithdrawOrder() {
            this.$http.get('/feiyu/proxy/withdrawList?' + tansParams(this.withdrawQuery))
                .then(res => {
                    this.withdrawList = res.rows
                    this.withdrawTotal = res.total
                }).catch(err => {
                this.$message.error(err.msg)
            })
        },
        handleWithdraw(row){
            this.$prompt('请输入提现金额', '提现', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            }).then(({value}) => {
                this.$http.post('/feiyu/proxy/withdraw?' + tansParams({
                    amt: value,
                    bankId: row.id,
                })).then(res => {
                    this.getProxyInfo()
                    this.getWithdrawOrder()
                    this.$message.success('提现发起成功')
                }).catch(err => {
                    this.$message.error(err.msg)
                })
            }).catch(() => {

            });
        },
        //获取代理信息
        getProxyInfo() {
            this.$http.post('/feiyu/proxy/proxyInfo')
                .then(res => {
                    localStorage.setItem('proxyInfo', JSON.stringify(res.data))
                    this.proxyInfo = res.data
                }).catch(err => {
                this.$message.error(err.msg)
            })
        },
        handleShowInviteLine(){
            //获取当前登录的域名
            let domain = window.location.origin + '?c='+this.proxyInfo.id
            //弹窗显示domain
            this.$confirm('邀请链接：'+domain, '邀请链接', {
                confirmButtonText: '复制',
            }).then(() => {
                //复制domain
                navigator.clipboard.writeText(domain).then(() => {
                    this.$message({
                        type: 'success',
                        message: '复制成功'
                    });
                }).catch(() => {
                    this.$message({
                        type: 'error',
                        message: '复制失败，请手动复制'
                    });
                });
            }).catch(() => {

            });
        },
        handleSetDiscount(row) {
            this.$prompt('请输入折扣(0.95表示95折)', '设置折扣', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            }).then(({value}) => {
                this.$http.get('/feiyu/proxy/changeDiscount?' + tansParams({
                    discount: value,
                    memberId: row.id,
                })).then(res => {
                    this.getInviteList()
                    this.$message.success('设置成功')
                }).catch(err => {
                    this.$message.error(err.msg)
                })
            }).catch(() => {

            });

        }
    }
};
</script>
