/**
 * 参数处理
 * @param {*} params  参数
 */
export function tansParams(params) {
    let result = ''
    for (const propName of Object.keys(params)) {
        const value = params[propName];
        var part = encodeURIComponent(propName) + "=";
        if (value !== null && value !== "" && typeof (value) !== "undefined") {
            if (typeof value === 'object') {
                for (const key of Object.keys(value)) {
                    if (value[key] !== null && value[key] !== "" && typeof (value[key]) !== 'undefined') {
                        let params = propName + '[' + key + ']';
                        var subPart = encodeURIComponent(params) + "=";
                        result += subPart + encodeURIComponent(value[key]) + "&";
                    }
                }
            } else {
                result += part + encodeURIComponent(value) + "&";
            }
        }
    }
    return result
}

export function addDateRange(params, dateRange, propName) {
    let search = params;
    search.params = typeof (search.params) === 'object' && search.params !== null && !Array.isArray(search.params) ? search.params : {};
    dateRange = Array.isArray(dateRange) ? dateRange : [];
    if (typeof (propName) === 'undefined') {
        search.params['beginTime'] = dateRange[0];
        search.params['endTime'] = dateRange[1];
    } else {
        search.params['begin' + propName] = dateRange[0];
        search.params['end' + propName] = dateRange[1];
    }
    return search;
}

export function getAllIpStatus() {
    return {
        NORMAL: `<span style="color: #409EFF">正常</span>`,
        DISABLE: `<span style="color: #F56C6C">禁用</span>`,
        WAIT_ACTIVE: `<span style="color: #909399">等待激活</span>`,
        SHIELD: `<span style="color: #F56C6C">被屏蔽</span>`,
        EXPIRE: `<span style="color: #E6A23C">已过期</span>`,
    }
}

export function getAllOrderStatus() {
    return {
        SUCCESS: `<span style="color: #67C23A">付款成功</span>`,
        FAIL: `<span style="color: #F56C6C">付款失败</span>`,
        WAIT: `<span style="color: #909399">等待支付</span>`,
        REFUND: `<span style="color: #E6A23C">已退款</span>`,
    }
}

export function getAllTradeType() {
    return {
        ALI_NATIVE: '支付宝支付',
        WX_NATIVE: '微信支付',
        ACCOUNT_BALANCE: '余额支付',
    }
}

export function getAllOrderType() {
    return {
        NEW_BUY: '新购',
        RENEW: '续费',
        UPGRADE: '升级带宽',
        RECHARGE: '充值',
    }
}

export function getAllIpTypeOption() {
    return {
        LINE_MODE: '线路',
        OPERATOR: '运营商',
        PROJECT: '项目',
        CYCLE: '周期',
        BANDWIDTH: '带宽',
        LINE_LEVEL: '线路类型',
    }
}

export function ipStatusFormat(str) {
    return getAllIpStatus()[str]
}

export function orderStatusFormat(str) {
    return getAllOrderStatus()[str] || '-'
}

export function tradeTypeFormat(str) {
    return getAllTradeType()[str] || '-'
}

export function orderTypeFormat(str) {
    return getAllOrderType()[str] || '-'
}

export function ipTypeOptionFormat(str) {
    return getAllIpTypeOption()[str] || '-'
}
