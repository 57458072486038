import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import router from "@/router";

import plugin from './api/plugin';

Vue.use(plugin);

Vue.config.productionTip = false
Vue.use(ElementUI);


Vue.filter('extractTextFromSpan', function(value) {
    const spanElement = document.createElement('span');
    spanElement.innerHTML = value;
    return spanElement.textContent || spanElement.innerText;
});

new Vue({
    router,
  render: h => h(App),
}).$mount('#app')
